import { useState } from 'react';
import {
  Navbar,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { hasAdministrativeRights } from '../../../utils/UserRights';
import { getUserProfilePic } from '../../../utils/Avatar';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import Avatar from '../Avatar';
import logo from '../../../assets/logo.svg';
import './hamburgerMenu.scss';
import './navbar.scss';

const NavbarAway = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const { getSignedInUser, signOut } = useAuth();

  const user = getSignedInUser();
  const hasAdminRights = hasAdministrativeRights(user);

  const toggle = () => {
    setIsOpen(!isOpen);
    setActiveMenu(!activeMenu);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleChangePasswordModal = () => {
    setChangePasswordModalOpen(!changePasswordModalOpen);
  };

  return (
    <>
      <div className="away-navbar">
        <Navbar className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <NavLink
              exact
              to="/"
              className="logo navbar-brand d-block d-sm-block d-md-block d-lg-none"
            >
              <img src={logo} alt="Logo" />
            </NavLink>
            <button
              onClick={toggle}
              className={
                activeMenu
                  ? 'hamburger hamburger--collapse is-active'
                  : 'hamburger hamburger--collapse'
              }
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <Collapse isOpen={isOpen} navbar>
              <NavLink
                exact
                to="/"
                className="logo navbar-brand d-none d-lg-block d-md-none"
                id="away-logo"
              >
                <img src={logo} alt="Logo" />
              </NavLink>
              <ul className="navbar-nav">
                <li className="nav-item" id="dashboard-nav">
                  <NavLink exact to="/" className="nav-link">
                    Dashboard
                  </NavLink>
                </li>
                {hasAdminRights && (
                  <li className="nav-item" id="teams-nav">
                    <NavLink to="/team" className="nav-link">
                      Teams
                    </NavLink>
                  </li>
                )}
                {hasAdminRights && (
                  <li className="nav-item" id="manage-users-nav">
                    <NavLink to="/users" className="nav-link">
                      People
                    </NavLink>
                  </li>
                )}
                {user.isApprover && (
                  <li className="nav-item" id="vacation-requests-nav">
                    <NavLink to="/vacation-requests" className="nav-link">
                      Requests
                    </NavLink>
                  </li>
                )}
                <li className="nav-item" id="vacation-requests-nav">
                  <NavLink to="/history" className="nav-link">
                    My History
                  </NavLink>
                </li>
                {hasAdminRights && (
                  <li className="nav-item" id="company-nav">
                    <NavLink to="/company" className="nav-link">
                      Company
                    </NavLink>
                  </li>
                )}
              </ul>
              <div className="away-nav-dropdown">
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  id="profile-dropdown"
                >
                  <DropdownToggle caret>
                    <Avatar
                      size="xs"
                      title={user.name}
                      isSignedInUser
                      avatar={getUserProfilePic(user)?.fullUrl}
                    />
                    <span>{user.name}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="dropdown-item" id="profile-nav">
                      <NavLink to="/profile" className="nav-link">
                        Profile
                      </NavLink>
                    </div>
                    {hasAdminRights && (
                      <div className="dropdown-item" id="reports-nav">
                        <NavLink to="/reports" className="nav-link">
                          Reports
                        </NavLink>
                      </div>
                    )}
                    <div className="dropdown-item" id="notif-settings-nav">
                      <NavLink to="/notification-settings" className="nav-link">
                        Notifications
                      </NavLink>
                    </div>
                    <div className="dropdown-item">
                      <button
                        type="button"
                        id="change-password-button"
                        className="btn"
                        onClick={toggleChangePasswordModal}
                      >
                        Change password
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <button
                        type="button"
                        className="btn"
                        id="logout-button"
                        onClick={signOut}
                      >
                        Logout
                      </button>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Collapse>
          </div>
        </Navbar>
      </div>
      <ChangePasswordModal
        toggleModal={toggleChangePasswordModal}
        showModal={changePasswordModalOpen}
        userId={user.id}
      />
    </>
  );
};

export default NavbarAway;
