import { toast } from 'react-toastify';

export const successPopup = (message, id) => {
  toast.success(message || 'Operation successful!', {
    icon: false,
    theme: 'colored',
    position: 'bottom-left',
    autoClose: 7000,
    pauseOnHover: false,
    draggable: false,
    toastId: id || null,
  });
};

export const errorPopup = (error, id) => {
  const toastConfig = {
    icon: false,
    theme: 'colored',
    position: 'bottom-left',
    autoClose: 10000,
    pauseOnHover: false,
    draggable: false,
  };
  const { graphQLErrors } = error;
  if (graphQLErrors && graphQLErrors.length) {
    graphQLErrors.map(err => {
      return toast.error(err.message || 'An error occurred!', {
        ...toastConfig,
        toastId: id || null,
      });
    });
  } else if (typeof error === 'string') {
    return toast.error(error, toastConfig);
  }
  return null;
};
