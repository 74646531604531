export const getRedirectfromLocation = props => {
  const {
    children: {
      props: {
        children: {
          props: { location },
        },
      },
    },
  } = props;

  const { pathname, search } = location;
  return { from: pathname, params: search };
};

export const getRedirectFromState = props => {
  const {
    children: {
      props: {
        children: {
          props: {
            location: { state },
          },
        },
      },
    },
  } = props;

  let from = '/';
  let params = '';
  if (state) {
    // if we are going directly to login there is no state
    from = state.from;
    params = state.params;
  }

  return { from, params };
};
