import PropTypes from 'prop-types';

const LayoutLoggedOut = ({ children }) => {
  return <div className="container">{children}</div>;
};

LayoutLoggedOut.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutLoggedOut;
