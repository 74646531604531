import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SplashScreen from './components/ui/SplashScreen/SplashScreen';
import AuthGuard from './components/auth/AuthGuard';
import GuestGuard from './components/auth/GuestGuard';
import LayoutLoggedOut from './components/layouts/LayoutLoggedOut';
import LayoutLoggedIn from './components/layouts/LayoutLoggedIn';
import { USER_ROLES } from './constants';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<SplashScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const allowedRoles = route.allowedRoles || undefined;
        const guardProps = {};
        if (allowedRoles) guardProps.allowedRoles = allowedRoles;

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Guard {...guardProps}>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/register',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/account/register')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/account/login')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/account/forgotPassword')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password/:hash',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/account/resetPassword')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/join/:inviteCode',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/account/join')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/dashboard')),
  },
  {
    exact: true,
    guard: AuthGuard,
    allowedRoles: [USER_ROLES.owner, USER_ROLES.hr, USER_ROLES.admin],
    path: '/team',
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/team')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/users',
    allowedRoles: [USER_ROLES.owner, USER_ROLES.hr, USER_ROLES.admin],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/users')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/users/:userId',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/profile')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/profile',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/profile')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/vacation-requests',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/vacationRequests')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/company',
    allowedRoles: [USER_ROLES.owner, USER_ROLES.admin],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/company')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/history',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/myHistory')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/notification-settings',
    allowedRoles: [
      USER_ROLES.owner,
      USER_ROLES.hr,
      USER_ROLES.admin,
      USER_ROLES.employee,
    ],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/notifications')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/reports',
    allowedRoles: [USER_ROLES.owner, USER_ROLES.hr, USER_ROLES.admin],
    layout: LayoutLoggedIn,
    component: lazy(() => import('./views/reports')),
  },
  {
    exact: true,
    path: '/404',
    layout: LayoutLoggedOut,
    component: lazy(() => import('./views/pageNotFound')),
  },
  {
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
