import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import GET_LOGGED_IN_USER_DATA from './GetUserDataGqlQuery';
import SplashScreen from '../components/ui/SplashScreen/SplashScreen';
import { baseURL } from '../constants';
import { getVacationDaysTotals } from '../utils/VacationDays';

const initialAuthState = {
  isAuthenticated: false,
  isInitialising: true,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_SERVER_ISSUE':
      return {
        ...state,
        isInitialising: false,
        isAuthenticated: false,
      };
    case 'AUTH_NO_TOKEN': {
      return {
        ...state,
        isInitialising: false,
        isAuthenticated: false,
      };
    }
    case 'AUTH_TOKEN_SIGN_IN': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isInitialising: false,
        user,
      };
    }
    case 'AUTH_TOKEN_INVALID': {
      return {
        ...state,
        isAuthenticated: false,
        isInitialising: false,
      };
    }
    case 'AUTH_SIGN_OUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  registerCompany: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  getSignedInUser: () => Promise.resolve(),
  refetchSignedInUser: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const [getUserData] = useLazyQuery(GET_LOGGED_IN_USER_DATA, {
    onCompleted: data => {
      const { user } = data;
      if (user) {
        dispatch({
          type: 'AUTH_TOKEN_SIGN_IN',
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              name: `${user.firstName} ${user.lastName}` || user.email,
              vacationDays: getVacationDaysTotals(user),
            },
          },
        });
      } else {
        dispatch({ type: 'AUTH_TOKEN_INVALID' });
      }
    },
    onError: err => {
      if (err.graphQLErrors.length) {
        dispatch({ type: 'AUTH_TOKEN_INVALID' });
      }
      if (err.networkError) {
        dispatch({ type: 'AUTH_SERVER_ISSUE' });
      }
    },
  });

  const signInWithEmailAndPassword = async (email, password) => {
    const body = new URLSearchParams();
    body.append('email', email);
    body.append('password', password);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const res = await axios.post(`${baseURL}auth/login`, body, config);
      if (res.data.access_token) {
        localStorage.setItem('token', res.data.access_token);
        return { token_received: true, error: null };
      }
      return { token_received: false, error: 'Access token not received' };
    } catch (err) {
      if (err.message !== 'Network Error') {
        return {
          token_received: false,
          error: `An error occurred: ${err.message}`,
        };
      }
      return {
        token_received: false,
        error: 'Server error',
      };
    }
  };

  const registerCompany = async (registerFormValues, registerNewOwner) => {
    try {
      const { data } = await registerNewOwner({
        variables: { register: registerFormValues },
      });
      const token = data.register.access_token;
      localStorage.setItem('token', token);
      window.location.href = '/';
    } catch (e) {
      return e;
    }
    return false;
  };

  const signOut = async () => {
    localStorage.removeItem('token');
    dispatch({ type: 'AUTH_SIGN_OUT' });
  };

  const getSignedInUser = () => {
    return state.user;
  };

  const refetchSignedInUser = () => {
    getUserData({
      variables: { Id: state.user.id },
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
    // if token present, attempt to fetch user data
    if (localStorage.getItem('token') !== null) {
      try {
        const decodedUserFromToken = jwt_decode(localStorage.getItem('token'));

        const userIdFromToken = decodedUserFromToken?.user?.id;
        if (userIdFromToken) {
          getUserData({
            variables: { Id: userIdFromToken },
            fetchPolicy: 'network-only',
          });
        }
      } catch {
        dispatch({ type: 'AUTH_TOKEN_INVALID' });
      }
    } else {
      dispatch({ type: 'AUTH_NO_TOKEN' });
    }
  }, []);

  if (state.isInitialising) return <SplashScreen />;
  return (
    <AuthContext.Provider
      value={{
        ...state,
        registerCompany,
        signInWithEmailAndPassword,
        signOut,
        getSignedInUser,
        refetchSignedInUser,
      }}
      displayName="Auth Context"
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
