import { gql } from '@apollo/client';

export const GET_CONTRACT_TYPES = gql`
  query getContractTypes {
    allTypes {
      id
      name
      type
    }
  }
`;

export const GET_LOCATIONS_BY_COMPANY = gql`
  query getLocationsByCompany {
    getLocationsByCompany {
      id
      name
    }
  }
`;

export const GET_DEPARTMENTS_BY_COMPANY = gql`
  query getDepartmentsByCompany {
    getDepartmentsByCompany {
      id
      name
    }
  }
`;

export const GET_TEAMS_BY_COMPANY = gql`
  query getTeamsByCompany {
    getTeamsByCompany {
      id
      name
    }
  }
`;

export const GET_VACATION_TYPES = gql`
  query getVacationTypes {
    vacationTypeForCompany {
      id
      name
      numberOfDays
      needsApproval
      requiresFullDays
      mandatory
      color
      country {
        id
        name
      }
      company {
        name
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`;

export const GET_NATIONAL_HOLIDAYS = gql`
  query getNationalHolidays($year: Int!) {
    getNationalHolidays(year: $year) {
      id
      localName
      date
    }
  }
`;
