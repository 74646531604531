// eslint-disable-next-line import/prefer-default-export
export const getVacationDaysTotals = user => {
  if (user && user.vacationTypes) {
    const vacationDays = user.vacationTypes.reduce(
      (acc, userVacation) => {
        const { vacationType, numberOfDays } = userVacation;
        // eslint-disable-next-line no-param-reassign
        acc.totalVacationDays += vacationType.numberOfDays;
        acc.vacationDaysLeft += numberOfDays;
        return acc;
      },
      { totalVacationDays: 0, vacationDaysLeft: 0 },
    );
    return vacationDays;
  }
  return {
    totalVacationDays: 0,
    vacationDaysLeft: 0,
  };
};
