import { gql } from '@apollo/client';

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePasswordMutation(
    $id: Int!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      changePasswordInput: {
        id: $id
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    )
  }
`;

export default CHANGE_PASSWORD_MUTATION;
