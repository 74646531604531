import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from './graphql/configGraphQL';
import { AuthProvider } from './contexts/AuthContext';
import { CompanyProvider } from './contexts/CompanyContext';
import routes, { renderRoutes } from './routes';
import './styles/theme.scss';

require('dotenv').config();

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AuthProvider>
          <CompanyProvider>{renderRoutes(routes)}</CompanyProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
