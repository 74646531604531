import Loader from 'react-loader-spinner';
import logo from '../../../assets/logo.svg';
import './SplashScreen.scss';

const SplashScreen = () => (
  <div className="splash-screen">
    <img src={logo} alt="Away" />
    <Loader type="ThreeDots" color="#ff8a5d" />
  </div>
);

export default SplashScreen;
