export function shortenName(data) {
  return data.split(' ').slice(0, 2);
}

export function getShortName(data) {
  const nameArray = shortenName(data);
  const secondNameChar = nameArray[1] ? nameArray[1].slice(0, 1) : '';
  return nameArray[0].concat(' ', secondNameChar, secondNameChar ? '.' : '');
}

export function getAlias(data) {
  const nameArray = shortenName(data);
  const firstNameChar = nameArray[0].slice(0, 1);
  const secondNameChar = nameArray[1] ? nameArray[1].slice(0, 1) : '';
  return firstNameChar.concat(secondNameChar);
}

export function getUserProfilePic(user) {
  if (user && user.images && user.images.length) {
    const profilePic = user.images.find(image => image.type === 'userProfile');
    if (profilePic) return profilePic;
  }
  return null;
}
