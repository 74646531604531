import PropTypes from 'prop-types';
import NavbarAway from '../ui/Navbar';

const LayoutLoggedIn = ({ children }) => {
  return (
    <>
      <NavbarAway />
      <div className="container">{children}</div>
    </>
  );
};

LayoutLoggedIn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutLoggedIn;
