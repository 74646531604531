import { USER_ROLES } from '../constants';

export const hasAdministrativeRights = user => {
  if (!user) return false;
  return (
    user.role.name === USER_ROLES.owner ||
    user.role.name === USER_ROLES.hr ||
    user.role.name === USER_ROLES.admin
  );
};

export const userIsOwner = user => user.role.name === USER_ROLES.owner;
