import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please enter your current password'),
  newPassword: Yup.string()
    .required('Please enter your new password')
    .min(8, 'Password is too short - should be at least 8 characters long')
    .max(100, 'Password is too long - maximum allowed is 100 characters')
    .matches(/[a-z]/, 'Password must contain lowercase letters.')
    .matches(/[A-Z]/, 'Password must contain uppercase letters.')
    .matches(/[0-9]/, 'Password must contain at least 1  number'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your new password'),
});

export default validationSchema;
