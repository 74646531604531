import React, { createContext, useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  GET_DEPARTMENTS_BY_COMPANY,
  GET_LOCATIONS_BY_COMPANY,
  GET_TEAMS_BY_COMPANY,
  GET_VACATION_TYPES,
  GET_COUNTRIES,
  GET_NATIONAL_HOLIDAYS,
  GET_CONTRACT_TYPES,
} from './CompanyDataGql';
import useAuth from '../hooks/useAuth';
import { errorPopup } from '../utils/Popup';
import { processCurrentYearNationalHolidays } from '../utils/NationalHolidays';
import SplashScreen from '../components/ui/SplashScreen/SplashScreen';

const initialCompanyState = {
  contractTypes: [],
  countries: [],
  locations: [],
  departments: [],
  teams: [],
  nationalHolidaysForWidget: [],
  nationalHolidaysForCalendar: [],
};

const CompanyContext = createContext({
  initialCompanyState,
});

export const CompanyProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const currentYear = new Date().getFullYear();
  const [
    nationalHolidaysForCalendar,
    setNationalHolidaysForCalendar,
  ] = useState([]);

  const [vacationTypes, setVacationTypes] = useState();

  const { data: contractTypesData, loading: contractTypesLoading } = useQuery(
    GET_CONTRACT_TYPES,
    {
      onError: () => {
        errorPopup('An error occurred fetching contract types');
      },
    },
  );

  const { data: locationsData, loading: locationsLoading } = useQuery(
    GET_LOCATIONS_BY_COMPANY,
    {
      onError: () => {
        errorPopup('An error occurred, try again later!');
      },
    },
  );

  const { data: departmentsData, loading: departmentsLoading } = useQuery(
    GET_DEPARTMENTS_BY_COMPANY,
    {
      onError: () => {
        errorPopup('An error occurred, try again later!');
      },
    },
  );

  const { data: teamsData, loading: teamsLoading } = useQuery(
    GET_TEAMS_BY_COMPANY,
    {
      onError: () => {
        errorPopup('An error occurred, try again later!');
      },
    },
  );

  const [getVacationTypes, { loading: vacationTypesLoading }] = useLazyQuery(
    GET_VACATION_TYPES,
    {
      onCompleted: data => {
        setVacationTypes(data.vacationTypeForCompany);
      },
      onError: err =>
        errorPopup(
          err.message || 'There was a problem fetching vacation types',
        ),
    },
  );

  const { data: countriesData, loading: countriesLoading } = useQuery(
    GET_COUNTRIES,
    {
      onError: err => {
        errorPopup(err.message || 'An error occurred, try again later!');
      },
    },
  );

  const {
    data: nationalHolidaysData,
    loading: nationalHolidaysLoading,
  } = useQuery(GET_NATIONAL_HOLIDAYS, {
    variables: { year: currentYear },
    onCompleted: data => {
      setNationalHolidaysForCalendar(data.getNationalHolidays);
    },
    onError: errorPopup,
  });

  const [
    getNationalHolidays,
    { loading: loadingNationalHolidaysForCalendar },
  ] = useLazyQuery(GET_NATIONAL_HOLIDAYS, {
    onCompleted: data => {
      setNationalHolidaysForCalendar(data.getNationalHolidays);
    },
    onError: err =>
      errorPopup(
        err.message || 'There was a problem fetching national holidays',
      ),
  });

  const refetchVacationTypes = () => {
    getVacationTypes({ fetchPolicy: 'network-only' });
  };

  useEffect(() => {
    getVacationTypes({ fetchPolicy: 'network-only' });
  }, []);

  if (!isAuthenticated) return <>{children}</>;

  const isInitializing =
    locationsLoading ||
    departmentsLoading ||
    teamsLoading ||
    vacationTypesLoading ||
    countriesLoading ||
    nationalHolidaysLoading ||
    contractTypesLoading ||
    !locationsData ||
    !departmentsData ||
    !teamsData ||
    !countriesData ||
    !vacationTypes ||
    !nationalHolidaysData ||
    !contractTypesData;

  if (isInitializing) return <SplashScreen />;

  const { getLocationsByCompany: locations } = locationsData;
  const { getDepartmentsByCompany: departments } = departmentsData;
  const { getTeamsByCompany: teams } = teamsData;
  const { countries } = countriesData;
  const { getNationalHolidays: nationalHolidays } = nationalHolidaysData;
  const { allTypes: contractTypes } = contractTypesData;

  const locationDropdownOptions = locations.map(location => ({
    value: location.id,
    label: location.name,
  }));

  const departmentDropdownOptions = departments.map(department => ({
    value: department.id,
    label: department.name,
  }));

  const teamDropdownOptions = teams.map(team => ({
    value: team.id,
    label: team.name,
  }));

  const vacationTypeDropdownOptions = vacationTypes.map(vacationType => ({
    value: vacationType.id,
    label: vacationType.name,
  }));

  const countryDropdownOptions = countries.map(country => ({
    label: country.name,
    value: country.id,
  }));

  const userStatusDropdownOptions = [
    { value: 1, label: 'active' },
    { value: 0, label: 'disabled' },
  ];

  const contractTypeOptions = contractTypes
    .filter(i => i.type === 'contract_type')
    .map(contractType => ({
      label: contractType.name,
      value: contractType.id,
    }));

  const { nationalHolidaysForWidget } = processCurrentYearNationalHolidays(
    nationalHolidays,
  );

  return (
    <CompanyContext.Provider
      value={{
        locations,
        locationDropdownOptions,
        departments,
        departmentDropdownOptions,
        teams,
        teamDropdownOptions,
        vacationTypes,
        vacationTypesLoading,
        refetchVacationTypes,
        vacationTypeDropdownOptions,
        userStatusDropdownOptions,
        countryDropdownOptions,
        nationalHolidaysForCalendar,
        getNationalHolidays,
        loadingNationalHolidaysForCalendar,
        nationalHolidaysForWidget,
        contractTypeOptions,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

CompanyProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CompanyContext;
