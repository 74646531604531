import { gql } from '@apollo/client';

const GET_LOGGED_IN_USER_DATA = gql`
  query getUserData($Id: ID!) {
    user(id: $Id) {
      id
      email
      firstName
      lastName
      status
      dob
      doh
      phone
      jobPosition
      contractType {
        id
        name
      }
      isApprover
      vacationTypes {
        id
        numberOfDays
        vacationType {
          name
          numberOfDays
        }
      }
      company {
        id
        name
        createdAt
      }
      department {
        id
        name
      }
      location {
        id
        name
        country {
          id
          name
        }
      }
      role {
        id
        createdAt
        name
      }
      createdAt
      updatedAt
      teamuserss {
        id
        user {
          id
          firstName
          lastName
        }
        team {
          id
          name
        }
      }
      images {
        id
        type
        url
        fullUrl
      }
    }
  }
`;

export default GET_LOGGED_IN_USER_DATA;
