import PropTypes from 'prop-types';

const { Modal, ModalBody, Button } = require('reactstrap');

const ConfirmationModal = ({ isOpen, toggle, onConfirm, modalText }) => {
  return (
    <Modal className="away-modal lg" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="away-modal-body">
        <div className="away-modal-line">
          <h5>{modalText}</h5>
        </div>
        <div className="away-modal-buttons">
          <Button color="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button className="btn-link btn-grey" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  modalText: 'Are you sure you want to do this?',
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalText: PropTypes.string,
};

export default ConfirmationModal;
