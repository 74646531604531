import Loader from 'react-loader-spinner';
import './Loader.scss';

const CustomLoader = () => (
  <div className="away-loader">
    <Loader type="ThreeDots" height={60} width={60} color="#ff8a5d" />
  </div>
);

export default CustomLoader;
