import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FormikProvider, useFormik, Field } from 'formik';
import { Alert, Modal, ModalBody, Button, Form, Spinner } from 'reactstrap';
import useAuth from '../../../hooks/useAuth';
import PasswordInput from '../../form/PasswordInput';
import validationSchema from './ChangePasswordFormValidation';
import { errorPopup, successPopup } from '../../../utils/Popup';
import CHANGE_PASSWORD_MUTATION from './changePasswordGql';

const ChangePasswordModal = ({ toggleModal, showModal }) => {
  const { getSignedInUser } = useAuth();
  const user = getSignedInUser();
  const [showError, setShowError] = useState(false);

  const [changePasswordMutation, { data }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
  );

  const handleCloseModal = () => {
    setShowError(false);
    toggleModal();
  };
  const passwordFormik = useFormik({
    initialValues: {
      id: user.id,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await changePasswordMutation({ variables: values });
        successPopup('Password changed successfully!');
        toggleModal();
      } catch (e) {
        errorPopup(e.message);
      } finally {
        passwordFormik.setSubmitting(false);
      }
    },
  });

  const successfullyChangedPassword = data && data.changePassword;

  return (
    <Modal className="away-modal" isOpen={showModal} toggle={toggleModal}>
      <ModalBody className="away-modal-body">
        <div className="away-modal-line">
          <h3>Change password</h3>
        </div>
        <Alert
          isOpen={showError}
          color="danger"
          toggle={() => setShowError(false)}
        >
          error
        </Alert>
        <FormikProvider value={passwordFormik}>
          <Form onSubmit={passwordFormik.handleSubmit}>
            <Field
              name="currentPassword"
              component={PasswordInput}
              label="Current password"
            />
            <Field
              name="newPassword"
              label="New password"
              component={PasswordInput}
            />
            <Field
              name="confirmNewPassword"
              label="Confirm new password"
              component={PasswordInput}
            />
            <div className="away-modal-buttons">
              <Button
                type="submit"
                disabled={
                  successfullyChangedPassword ||
                  passwordFormik.isSubmitting ||
                  JSON.stringify(passwordFormik.values) ===
                    JSON.stringify(passwordFormik.initialValues)
                }
                className="btn btn-primary"
              >
                {passwordFormik.isSubmitting ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  'Change password'
                )}
              </Button>
              <Button onClick={handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default ChangePasswordModal;
