import PropTypes from 'prop-types';
import CustomLoader from '../Loader/Loader';
import { titleBasedAvatarColor, colorArray } from '../../../utils/Color';

const AvatarColor = ({ size, avatar, title, isChangingAvatar, children }) => {
  const avatarColorHere = title
    ? titleBasedAvatarColor(title)
    : colorArray[Math.floor(Math.random() * colorArray.length)];

  const avatarContent = isChangingAvatar ? (
    <div className={`avatar avatar-${size}`} title={title}>
      <CustomLoader />
    </div>
  ) : (
    <div
      className={`avatar avatar-${size} ${
        !avatar ? `avatar-text avatar-${avatarColorHere}` : ''
      }`}
      title={title}
    >
      {children}
    </div>
  );

  return <div>{avatarContent}</div>;
};

AvatarColor.defaultProps = {
  size: 'xs',
  avatar: '',
  title: 'Avatar title',
  isChangingAvatar: false,
  children: null,
};

AvatarColor.propTypes = {
  size: PropTypes.string,
  avatar: PropTypes.string,
  title: PropTypes.string,
  isChangingAvatar: PropTypes.bool,
  children: PropTypes.node,
};

export default AvatarColor;
