export const USER_ROLES = {
  owner: 'Owner',
  hr: 'HR',
  admin: 'Admin',
  employee: 'Employee',
};

export const userRoles = [
  { value: 1, label: USER_ROLES.employee },
  { value: 2, label: USER_ROLES.hr },
  { value: 3, label: USER_ROLES.admin },
  { value: 4, label: USER_ROLES.owner },
];

export const nonOwnerUserRoles = userRoles.filter(role => role.value !== 4);
export const isOwnerUserRole = role => role.name === USER_ROLES.owner;

// https://away-client.lateral-dev.com/

const getApiURL = () => {
  const PRODUCTION_API_URL = 'https://api-vacations.lateral-inc.com/';
  const STAGING_API_URL = 'https://away-api.lateral-dev.com/';
  const LOCAL_API_URL = 'http://localhost:8080/';

  if (process.env.REACT_APP_API_URL === 'staging') {
    return STAGING_API_URL;
  }
  if (process.env.REACT_APP_API_URL === 'production') {
    return PRODUCTION_API_URL;
  }
  return LOCAL_API_URL;
};

export const baseURL = getApiURL();

export const userStatusList = [
  {
    value: 0,
    label: 'Inactive Users',
  },
  {
    value: 1,
    label: 'Active Users',
  },
];

export const VACATION_STATUS = {
  accepted: 'Accepted',
  pending: 'Pending',
  rejected: 'Rejected',
  canceled: 'Canceled',
};

export const CALENDAR_COLORS = {
  defaultEvent: '#f9a73199',
  firstYearAnniversary: '#8978CE',
  anniversary: '#2176DC',
  birthday: '#ED6E68',
  nationalHoliday: '#FF1744',
};
export const DEFAULT_VACATION_COLORS = {
  'Paid time off': '#f9a731',
  'Sick leave': '#aa55ff99',
  Unpaid: '#0d2668',
  default: '#f9a731',
};

export const CALENDARS = {
  vacations: 'vacations',
  anniversaries: 'anniversaries',
};

export const APPROVER_TYPES = {
  department: 'Department',
};

export const NOTIFICATION_TYPES = {
  work: 'Work Anniversary',
  birthday: 'Birthday',
};

export const APPROVER_CATEGORY_TYPES = {
  department: 'Department',
  team: 'Team',
  user: 'User',
  employee: 'Employee',
  vacation: 'Vacation',
};

export const EMAIL_ACTIONS = {
  approve: 'approve',
  reject: 'decline',
};

export const NOTIFICATION_CATEGORY_TYPES = {
  user: 'User',
  team: 'Team',
  department: 'Department',
};
