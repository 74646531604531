import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { getShortName, getAlias } from '../../../utils/Avatar';
import AvatarColor from '../AvatarColor';
import './avatar.scss';
import ConfirmationModal from '../../modals/ConfirmationModal';

const Avatar = ({
  size,
  title,
  avatar,
  userId,
  hasLabel,
  isSignedInUser,
  isCenter,
  isEditable,
  isLink,
  onEdit,
  onDelete,
  isChangingAvatar,
}) => {
  const [isConfirmDeleteAvatarOpen, setIsConfirmDeleteAvatarOpen] = useState(
    false,
  );
  let avatarCircle;
  const aliasHere = title && getAlias(title);
  const shortTitleHere = title && getShortName(title);

  if (avatar) {
    avatarCircle = <img src={avatar} alt={aliasHere} title={title} />;
  } else {
    avatarCircle = aliasHere && (
      <div className="avatar-placeholder-text" title={title}>
        {aliasHere}
      </div>
    );
  }

  const editBtn = (
    // eslint-disable-next-line
    <span className="edit-icon" onClick={avatar ? () => {} : onEdit}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  const deleteBtn = (
    // eslint-disable-next-line
    <span
      className={`delete-icon ${avatar ? '' : 'not-allowed'}`}
      onClick={avatar ? () => setIsConfirmDeleteAvatarOpen(true) : () => {}}
    >
      <FontAwesome name="trash" className="fa-small" />
    </span>
  );

  const editAvatarCircle = isEditable && (
    <div className="avatar-edit-wrapper">
      <div className="avatar-edit-flag">
        <FontAwesome name="camera" className="fa-small" />
      </div>
      <div
        className={`avatar-edit-container  ${
          window.location.pathname === '/company'
            ? 'avatar-companylogo-container'
            : ''
        }`}
      >
        {!avatar && editBtn}
        {avatar && deleteBtn}
      </div>
      <ConfirmationModal
        isOpen={isConfirmDeleteAvatarOpen}
        toggle={() => setIsConfirmDeleteAvatarOpen(!isConfirmDeleteAvatarOpen)}
        modalText="Are you sure you want to delete your profile picture?"
        onConfirm={() => {
          onDelete();
          setIsConfirmDeleteAvatarOpen(false);
        }}
      />
    </div>
  );

  let avatarContainer;
  const avatarContent = (
    <div>
      <AvatarColor
        size={size}
        avatar={avatar}
        title={title}
        isChangingAvatar={isChangingAvatar}
      >
        {avatarCircle}
        {isEditable && editAvatarCircle}
      </AvatarColor>
      {hasLabel && shortTitleHere && (
        <div className="avatar-label">{shortTitleHere}</div>
      )}
    </div>
  );

  if (isLink) {
    avatarContainer = (
      <Link to={isSignedInUser ? `/profile` : `/users/${userId}`}>
        {avatarContent}
      </Link>
    );
  } else {
    avatarContainer = <div>{avatarContent}</div>;
  }

  return (
    <div
      className={`away-avatar ${isCenter ? 'avatar-center' : ''} ${
        isLink ? 'away-avatar-link' : ''
      }`}
    >
      {avatarContainer}
    </div>
  );
};

Avatar.defaultProps = {
  size: 'md',
  isCenter: false,
  title: 'Avatar title',
  avatar: null,
  hasLabel: false,
  userId: null,
  isSignedInUser: false,
  isEditable: false,
  isLink: true,
  onEdit: null,
  onDelete: null,
  isChangingAvatar: false,
};
Avatar.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
  title: PropTypes.string,
  avatar: PropTypes.string,
  hasLabel: PropTypes.bool,
  isCenter: PropTypes.bool,
  userId: PropTypes.number,
  isSignedInUser: PropTypes.bool,
  isEditable: PropTypes.bool,
  isLink: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isChangingAvatar: PropTypes.bool,
};

export default Avatar;
