import PropTypes, { string } from 'prop-types';
import cx from 'classnames';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { useState } from 'react';

const PasswordInput = ({
  field,
  placeholder,
  form: { touched, errors, setFieldTouched },
  id,
  label,
  labelClassName,
  ...custom
}) => {
  const [showPassword, setShowPassword] = useState(false);
  // formik does not support active meta, so we have to use a prop for this.
  const [active, setActive] = useState(false);
  const labelActive =
    active || field.value || field.value === 0 || field.value !== '';
  const labelClass = labelActive
    ? cx(labelClassName, 'active')
    : labelClassName;

  const toggleShowPassword = e => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <FormGroup>
      <Label className={labelClass} for={id}>
        {label}
      </Label>
      <Input
        autoComplete="off"
        invalid={touched && !!errors[field.name]}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...custom}
        type={showPassword ? 'text' : 'password'}
        onBlur={() => {
          setActive(false);
          setFieldTouched(field.name);
        }}
        onFocus={() => setActive(true)}
      />

      {/* eslint-disable-next-line  */}
      <span
        onClick={!field.value ? () => {} : toggleShowPassword}
        className={`showPassword ${!field.value && 'disabled'}`}
      >
        {showPassword ? (
          <FontAwesome name="eye" className="fa-small" />
        ) : (
          <FontAwesome name="eye-slash" className="fa-small" />
        )}
      </span>
      {touched[field.name] && errors[field.name] ? (
        <FormFeedback id="error-message">{errors[field.name]}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

PasswordInput.defaultProps = {
  label: null,
  placeholder: null,
  id: null,
  labelClassName: null,
  type: null,
};

PasswordInput.propTypes = {
  field: PropTypes.shape({ name: string, value: string }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  labelClassName: PropTypes.string,
};

export default PasswordInput;
