export const colorArray = [
  'red',
  'blue',
  'purple',
  'green',
  'pink',
  'orange',
  'light-blue',
  'dark-green',
];

export const titleBasedAvatarColor = title => {
  let total = 0;
  for (let i = 0; i < title.length; i += 1) {
    total += title.charCodeAt(i);
  }

  const indexInMap = total % colorArray.length;

  return colorArray[indexInMap];
};
