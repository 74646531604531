import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { getRedirectFromState } from '../../utils/Redirect';

const GuestGuard = props => {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const redirectData = getRedirectFromState(props);
    return <Redirect to={`${redirectData.from}${redirectData.params}`} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
