// eslint-disable-next-line import/prefer-default-export
export const processCurrentYearNationalHolidays = nationalHolidays => {
  if (nationalHolidays && nationalHolidays.length) {
    const sortedNationalHolidays = nationalHolidays
      .slice()
      .sort((ev1, ev2) => new Date(ev1.date) - new Date(ev2.date));

    const upcomingNationalHolidays = sortedNationalHolidays.filter(
      ev => new Date(ev.date) >= new Date(),
    );

    const firstFiveUpcomingNationalHolidays = upcomingNationalHolidays.slice(
      0,
      4,
    );
    const restOfUpcomingHolidays = upcomingNationalHolidays.slice(4);

    return {
      nationalHolidaysForWidget: {
        firstFiveUpcomingNationalHolidays,
        restOfUpcomingHolidays,
      },
    };
  }

  return {
    nationalHolidaysForWidget: {
      firstFiveUpcomingNationalHolidays: [],
      restOfUpcomingHolidays: [],
    },
  };
};
