import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { getRedirectfromLocation } from '../../utils/Redirect';

const AuthGuard = props => {
  const { children, allowedRoles } = props;
  const { isInitialising, isAuthenticated, user } = useAuth();
  const state = getRedirectfromLocation(props);
  if (!isAuthenticated && !isInitialising) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state,
        }}
      />
    );
  }
  if (allowedRoles != null) {
    if (!allowedRoles.includes(user.role.name)) return <Redirect to="/" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AuthGuard;
